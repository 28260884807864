import React, { useState, useEffect } from 'react';
import './header.css'
import { Decrypt } from '../../Util/Crypto';
import { ScreenEnum } from '../../Util/Screen';
import ModalRecoverPsw from '../Modals/RecoverPsw';
import { FaHome } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import {
    AiOutlineHistory,
    AiFillControl
} from 'react-icons/ai';
import { Bs123 } from "react-icons/bs";
import { FaCheck, FaUserPlus } from "react-icons/fa";
import { TiSortNumerically } from "react-icons/ti";
import { FaGears } from "react-icons/fa6";
import { HiDocumentSearch } from "react-icons/hi";
import { BiImport } from "react-icons/bi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { PiUserCircleDuotone } from 'react-icons/pi';
import { MdOutlinePassword } from "react-icons/md";

function Header({ children }) {

    let user = window.localStorage.getItem("user");
    if (!user) {
        window.location.href = "/"
    } else {
        user = JSON.parse(Decrypt(user));
    }

    const Logout = () => {
        window.localStorage.removeItem("user");
        window.location.href = '/';
    };

    let companyName = window.localStorage.getItem("empresa");

    const [open, setOpen] = useState(false);
    const [scrrens, setScrren] = useState([]);

    useEffect((_ => {
        const data = JSON.parse(atob(window.localStorage.getItem("screens")));
        if (!Array.isArray(data.role)) {
            setScrren([data.role]);
        } else {
            setScrren(data.role);
        }
    }), [])

    const toggleMenu = () => {
        const menu__item = document.querySelectorAll('.menu__link');
        menu__item.forEach(x => {
            x.classList.toggle('menu__link__active');
        })
        document.querySelector('.menu')
            .classList.toggle('menu__link__active__bg');
    }

    return (
        <main className='container-main'>
            <nav>
                <div className='container-main-menu'>
                    <button onClick={toggleMenu} type='button' className='btn-main-menu'><span><FaHandHoldingDollar size={25} /> Hub Telefonia</span></button>
                </div>
                <div className='user-main-2'>
                    <div className='user-main'>
                        <div className="spacer-main"></div>
                        <PiUserCircleDuotone size={25} />
                        <span class="username-main">{companyName ? `${companyName}: ` : ""} {user.usuario_nome.split(' ')[0]}</span>
                    </div>
                    <div className='container-main-menu' style={{ marginLeft: '30px' }}>
                        <button className='btn-main-menu' size={25} onClick={Logout}>
                            <span><FiLogOut size={25} /> Sair</span>
                        </button>
                    </div>
                </div>
            </nav>
            <div class="menu-container">
                <ul class="menu">
                    <li class="menu__item">
                        <a href="/home" class="menu__link">
                            <span class="menu__text">INÍCIO</span>
                            <span class="menu__icon"><FaHome size={25} /></span>
                        </a>
                    </li>

                    <li class="menu__item">
                        <a href="/allcpf" class="menu__link">
                            <span class="menu__text">ENRIQUECIMENTO MAILING</span>
                            <span class="menu__icon"><BsFillTelephoneFill size={25} /></span>
                        </a>
                    </li>

                    <li class="menu__item">
                        <a href="/importbase" class="menu__link">
                            <span class="menu__text">IMPORTAR BASE</span>
                            <span class="menu__icon"><BiImport size={25} /></span>
                        </a>
                    </li>

                    <li class="menu__item">
                        <a href="/history" class="menu__link">
                            <span class="menu__text">IMPORTAR TELEFONIA</span>
                            <span class="menu__icon"><AiOutlineHistory size={25} /></span>
                        </a>
                    </li>

                    {user.usuario_tipo === 1 && <li class="menu__item">
                        <a href="/painelcontrole" class="menu__link">
                            <span class="menu__text">PAINEL</span>
                            <span class="menu__icon"><AiFillControl size={25} /></span>
                        </a>
                    </li>}

                    {user.usuario_tipo === 1 && <li class="menu__item">
                        <a href="/approval" class="menu__link">
                            <span class="menu__text">APROVAÇÃO QUARENTENA</span>
                            <span class="menu__icon"><FaCheck size={25} /></span>
                        </a>
                    </li>}

                    {user.usuario_tipo === 1 && <li class="menu__item">
                        <a href="/category" class="menu__link">
                            <span class="menu__text">SCORE</span>
                            <span class="menu__icon"><TiSortNumerically size={25} /></span>
                        </a>
                    </li>}

                    {user.usuario_tipo === 1 && <li class="menu__item">
                        <a href="/ocorrencia" class="menu__link">
                            <span class="menu__text">OCORRÊNCIA</span>
                            <span class="menu__icon"><FaGears size={25} /></span>
                        </a>
                    </li>}

                    <li class="menu__item">
                        <a href="/search" class="menu__link">
                            <span class="menu__text">FILTRAR UNICO CPF</span>
                            <span class="menu__icon"><HiDocumentSearch size={25} /></span>
                        </a>
                    </li>

                    {user.usuario_tipo !== 3 && <li class="menu__item">
                        <a href="/users" class="menu__link">
                            <span class="menu__text">USUÁRIOS</span>
                            <span class="menu__icon"><FaUserPlus size={25} /></span>
                        </a>
                    </li>}

                    <li class="menu__item">
                        <a onClick={() => document.getElementById("modalrecoversw").click()} class="menu__link">
                            <span class="menu__text">ALTERAR SENHA</span>
                            <span class="menu__icon"><MdOutlinePassword size={25} /></span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="container" id='container-content'>
                <div style={{ display: 'none' }}>
                    <ModalRecoverPsw />
                </div>
                {children}
            </div>
        </main>
    );
}

export default Header;