import React, { useEffect, useState, useRef } from 'react';
import './history.css';
import { DownloadFile } from '../../Util/FileUtil';
import { openAlert } from '../../Util/AlertUtil';
import { api } from '../../components/Api/api.js';
import { Decrypt } from "../../Util/Crypto";
import { ScreenEnum } from '../../Util/Screen';
import Pagination from '../../components/Pagination/pagination.jsx';
import SelectLayout from '../../components/Modals/SelectLayout.jsx';
import { FaFile } from "react-icons/fa";
import axios from "axios";
import { FaGear } from "react-icons/fa6";
import { FaSpinner } from 'react-icons/fa6';

const ImportHistory = (props) => {
    const [history, setHistory] = useState([]);
    const [records, setRecords] = useState([]);
    const [layoutSelectedText, setLayoutSelectedText] = useState('');
    const [layoutSelectedId, setLayoutSelectedId] = useState(0);
    const [selectedLayout, setSelectedLayout] = useState(null);
    const refPagination = useRef();
    const [fileSelect, setFileSelect] = useState(null);
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvFirstLine, setFirstLine] = useState([]);
    const [visualizacaoDados, setVisualizacaoDados] = useState([]);
    const [map, setMap] = useState({
        OPERADORA: -1,
        CPF: -1,
        DATAATUALIZACAO: -1,
        SCORE: -1,
        TELEFONE: -1,
        DDD: -1,
        FACEBOOK: -1,
        WHATSAPP: -1
    });
    const [loaderVisualizacaoDados, setloaderVisualizacaoDados] = useState(false);

    const getHistoryExcel = () => {
        let user = window.localStorage.getItem("user");
        user = JSON.parse(Decrypt(user));
        api.get(`File/GetHistoricoExcel?usuario_id=${user.usuario_id}&tela=${ScreenEnum.HISTORICO_TELEFONE}`, res => {
            setHistory(res.data);

            refPagination.current.Reload(res.data);
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })
    }

    const refrashPage = async _ => {
        try {
            let user = window.localStorage.getItem("user");
            user = JSON.parse(Decrypt(user));

            const config = {
                headers: {
                    Authorization: 'Bearer ' + api.access_token
                }
            };
            const uri = `${api.urlBase}File/GetHistoricoExcel?usuario_id=${user.usuario_id}&tela=${ScreenEnum.HISTORICO_TELEFONE}`;
            const response = await axios.get(uri, config);
            const jsonData = response.data;

            setHistory(jsonData);
            refPagination.current.Reload(jsonData);
        } catch (error) {
            console.error('Ocorreu um erro ao buscar os dados:', error.message);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            refrashPage();
        }, 5000)
    }, [history])

    useEffect(() => {
        getHistoryExcel();
    }, [])


    const ReloadPage = () => {
        let user = window.localStorage.getItem("user");
        user = JSON.parse(Decrypt(user));
        api.get(`File/GetHistoricoExcel?usuario_id=${user.usuario_id}&tela=${ScreenEnum.HISTORICO_TELEFONE}`, res => {
            setHistory(res.data);

            refPagination.current.Reload(res.data);
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })
    }


    const haddleSubmit = () => {
        const file = fileSelect;
        const hasRedCell = visualizacaoDados.some((item, index) => item.HeaderCSV !== item.ItemLayout);
        if (hasRedCell) {
            openAlert('Há uma ou mais colunas com erros. Por favor, corrija antes de enviar o arquivo.', false, true);
            setLayoutSelectedText('');
            setLayoutSelectedId(0);
            setFileSelect('');
            clearFileInput();
            setCsvHeaders('');
            setFirstLine();
            var input = document.querySelector('input[type="file"]')
            input.value = '';
            refrashPage();
            return;
        }
        else if (file != null) {
            let user = window.localStorage.getItem("user");
            user = JSON.parse(Decrypt(user));

            var input = document.querySelector('input[type="file"]')
            var data = new FormData()
            data.append('User_Id', user.usuario_id)
            data.append('Tipo_Layout', selectedLayout)
            for (const file of input.files) {
                data.append('Files', file, file.name)
            }

            console.log("map0");
            console.log(map);
            const mapeamento = {
                Operadora: map.OPERADORA,
                CPF: map.CPF,
                Data_Atualizacao: map.DATAATUALIZACAO,
                Score: map.SCORE,
                Tel: map.TELEFONE,
                DDD: map.DDD === 0 ? -1 : map.DDD,
                Facebook: map.FACEBOOK,
                Whatsapp: map.WHATSAPP
            };

            data.append('mapeamentoTxt', JSON.stringify(mapeamento));
            data.append('nomeLayoutOrigem', layoutSelectedText);

            api.post("File/AddFilaHistorico", data, res => {
                ReloadPage()
                openAlert(res.data);
                setLayoutSelectedText('');
                setLayoutSelectedId(0);
                input.value = '';
            }, erro => {
                input.value = '';
                if (Array.isArray(erro.response.data)) {
                    let erros = "";
                    erro.response.data.forEach(element => {
                        erros += "\n" + element.descricao + "\n";
                    });
                    openAlert('Algo deu errado\n erro:' + erros, false)
                    ReloadPage()
                    setLayoutSelectedText('');
                    setLayoutSelectedId(0);
                    setCsvHeaders("");
                }
                else {
                    ReloadPage()
                    openAlert('Algo deu errado\n erro:' + erro.response.data, false)
                    setLayoutSelectedText('');
                    setLayoutSelectedId(0);
                    setCsvHeaders("");
                }
            });
        } else {
            openAlert('Selecione um arquivo', false, true)
        }

    }

    const clearFileInput = () => {
        document.getElementById("filename").innerHTML = `Selecione um arquivo.`;
    }

    function removerAcentos(texto) {
        return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name;
            if (!fileName.endsWith('.csv')) {
                var input = document.querySelector('input[type="file"]')
                input.value = '';
                openAlert('Selecione um arquivo CSV.', false, true);
                return;
            }
            setloaderVisualizacaoDados(true);
            const reader = new FileReader();
            reader.onload = async (event) => {

                const content = removerAcentos(decodeURIComponent(event.target.result));
                //const content = event.target.result;
                const allLines = content.split(/\r\n|\n/);
                // Considera a primeira linha como cabeçalho
                const headers = allLines[0].split(';');
                const firstLine = allLines[1].split(';');
                setFirstLine(firstLine);
                setCsvHeaders(headers); // Salva os cabeçalhos do CSV

                const layoutDet = await GetLayout();
                if (layoutDet) {
                    console.log("layoutDet");
                    console.log(layoutDet);
                    // Supondo que layoutDet seja uma lista de objetos com { nome, posicao }
                    const newMap = layoutDet.reduce((acc, item) => {
                        acc[item.descricao] = item.posicao; // Mapeia o nome para a posição
                        return acc;
                    }, {});
                    console.log("newMap");
                    console.log(newMap);

                    setMap(newMap); // Atualiza o estado map com as novas posições
                    criarObjetoVisualizacao(layoutDet, headers, firstLine);
                }
                setloaderVisualizacaoDados(false);
            };

            document.getElementById("filename").innerHTML = `Arquivo selecionado: ${fileName}`

            reader.readAsText(e.target.files.item(0), 'ISO-8859-4');
            setFileSelect(file);
            //haddleSubmit(file);
        }
    };

    const DownloadFile = (file) => {
        let csvContent = atob(file);
        var blob = new Blob([csvContent], { type: "data:application/octet-stream;base64" });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url
        a.download = "log.csv";
        a.click();
    }

    const renderCsvHeaderWithLayoutSelect = () => {
        return (
            <table className='table table-striped' style={{ marginTop: '20px' }}>
                <tbody>
                    <tr>
                        {visualizacaoDados.map((item, index) => (
                            <td key={index} style={{ color: item.HeaderCSV !== item.ItemLayout ? 'red' : 'black' }}>
                                <strong>{item.ItemLayout}</strong><br />
                                <u><label style={{ display: 'block', marginTop: '10px' }}>
                                    {item.HeaderCSV}
                                </label></u>
                                <u>
                                    <label style={{
                                        display: 'block', marginTop: '5px', width: '100%', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'
                                    }}>
                                        {map[item.ItemLayout] !== -1 && csvFirstLine[map[item.ItemLayout]]}
                                    </label>
                                </u>
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        );
    };
    const handleMapChange = (fieldName, newPosition) => {
        const newMap = { ...map };
        newMap[fieldName] = parseInt(newPosition);
        setMap(newMap);
    };

    const formatTimeUnit = (unit) => unit.toString().padStart(2, '0');

    function criarObjetoVisualizacao(layout, headers, firstLine) {
        console.log(layout);
        console.log(headers);
        console.log(firstLine);
        const visualizacaoObj = layout
            //.sort((a, b) => a.posicao - b.posicao) // Ordena os itens do layout pela posição
            .map(itemLayout => {
                const posicao = itemLayout.posicao; // Ajuste da posição para índice do array
                const header = headers[posicao];
                const valor = firstLine[posicao];

                return {
                    ItemLayout: itemLayout.descricao,
                    HeaderCSV: header,
                    ValorPrimeiraLinha: valor
                };
            });
        console.log(visualizacaoObj);
        console.log("visualizacaoObj");
        setVisualizacaoDados(visualizacaoObj);
    }

    const GetLayout = async () => {
        try {
            const response = await api.getAsync(`File/BuscarLayout?layoutId=${layoutSelectedId}`);
            console.log("response", response.data);
            return response.data; // Retorna os dados assim que a chamada API é concluída
        } catch (erro) {
            openAlert('Algo deu errado\n erro:' + erro, false);
            console.error('Erro ao buscar os detalhes do layout:', erro);
            return null; // Retorna null em caso de erro
        }
    };

    const GetFileLog = (id) => {
        api.get(`File/GetDownloadExcel?arquivo_id=${id}&ehLog=true`, res => {
            DownloadFile(res.data);
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })
    }

    return (
        <section className='container'>
            <br />
            {!loaderVisualizacaoDados && csvHeaders.length === 0 && <div className="row" >
                <div className="col-12" style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div class="card">
                        <h3>Histórico telefonia</h3>
                        <div class="drop_box">
                            <header>
                                <h4 id="layoutSelected">{layoutSelectedText}</h4>
                                <h4 id="filename">Selecione um arquivo</h4>
                            </header>
                            <p>Arquivos suportados: .csv </p>
                            <input onChange={handleFileChange} type="file" hidden accept=".csv" id="fileID" style={{ display: 'none' }} />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', width: '100%' }}>
                                <SelectLayout
                                    setSelectedLayout={setSelectedLayout}
                                    setLayoutSelectedText={setLayoutSelectedText}
                                    setLayoutSelectedId={setLayoutSelectedId}
                                />
                                {
                                    layoutSelectedText != '' && layoutSelectedId !== 0 &&
                                    <button onClick={() => document.getElementById("fileID").click()} style={{ opacity: '0.7' }} class="btn btn-primary">Escolher arquivo</button>
                                }
                            </div>
                        </div>
                        <button onClick={() => DownloadFile('/Templates/template_historico_telefonia.csv')} className="btn btn-success" type="button">Baixar Template Padrão</button>
                    </div>
                </div>
            </div>}
            {loaderVisualizacaoDados && <div style={{ display: 'flex', justifyContent: 'center' }}><FaGear className='spinner' color='#1b6ec2' size={50} /><br /></div>}
            <div>
                {!loaderVisualizacaoDados && csvHeaders.length > 0 && (
                    <div class="drop_box" style={{ overflowX: 'auto', width: '100%', display: 'block' }}>
                        <h2>Pré-visualização</h2>
                        <right><h6>1 - Primeira linha é do Layuout cadastrado.</h6>
                            <h6>2 - Segunda linha é o cabeçalho do CSV.</h6>
                            <h6>3 - Terceira linha é uma amostra do valor encontrado no CSV para validação da estrutura do layout.</h6>
                            <h6>4 - Coluna em vermelho possui divergência com o layout cadastrado.</h6>
                            <h9>Obs: Caso os valores não correspondam com o cabeçalho, favor validar o Layout cadastrado. O mesmo deve está de acordo com o Excel levando em consideção a posição das colunas.</h9>
                        </right>
                        {renderCsvHeaderWithLayoutSelect()}
                        {visualizacaoDados.length == 0 && <div style={{ display: 'flex', justifyContent: 'center',padding:10 }}><FaGear className='spinner' color='#1b6ec2' size={50} /><br /></div>}
                        <div style={{ width: '100%', height: '1px', background: 'rgba(0,0,0,0.2)', marginBottom: '10px' }} />
                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', gap: 20 }}>
                            <button onClick={() => setCsvHeaders([])} style={{ opacity: '0.7' }} className="btn btn-primary">Voltar</button>
                            <button onClick={haddleSubmit} style={{ opacity: '0.7' }} className="btn btn-primary">Enviar Arquivo</button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ width: '100%', height: '1px', background: 'rgba(0,0,0,0.2)', marginBottom: '10px' }} />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {history.length === 0 && <span> Não há historico de importação</span>}
                {history.length > 0 && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Nome do arquivo</th>
                                <th>Data de envio</th>
                                <th>Tempo processamento</th>
                                <th>status</th>
                                <th>Total linhas</th>
                                <th>Total linhas importadas</th>
                                <th>Percentual</th>
                                <th>Layout</th>
                                <th>Log</th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.map((item, i) => {
                                const date = new Date(item.data_Cadastro);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                const hours = String(date.getHours()).padStart(2, '0');
                                const minutes = String(date.getMinutes()).padStart(2, '0');
                                const seconds = String(date.getSeconds()).padStart(2, '0');
                                const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

                                const now = new Date(item.horaAtual);
                                const inicioProcessamento = item.data_Cadastro ? new Date(item.data_Cadastro) : null;
                                const fimProcessamento = item.fim_Processamento ? new Date(item.fim_Processamento) : null;
                                const difBd = (inicioProcessamento && fimProcessamento) ? inicioProcessamento - fimProcessamento : 0;
                                const diffMs = inicioProcessamento ? now - inicioProcessamento : 0;

                                const diffHoursBd = Math.floor(difBd / 3600000);
                                const diffMinutesBd = Math.floor((difBd % 3600000) / 60000);
                                const diffSecondsBd = Math.floor((difBd % 60000) / 1000);
                                const diffHours = Math.floor(diffMs / 3600000);
                                const diffMinutes = Math.floor((diffMs % 3600000) / 60000);
                                const diffSeconds = Math.floor((diffMs % 60000) / 1000);

                                const formattedDiffHours = formatTimeUnit(diffHours);
                                const formattedDiffMinutes = formatTimeUnit(diffMinutes);
                                const formattedDiffSeconds = formatTimeUnit(diffSeconds);
                                const formattedDiffHoursBd = formatTimeUnit(diffHoursBd);
                                const formattedDiffMinutesBd = formatTimeUnit(diffMinutesBd);
                                const formattedDiffSecondsBd = formatTimeUnit(diffSecondsBd);

                                const tempoProcessamento =
                                    item.statusProcessamento === 0 || item.statusProcessamento === 1
                                        ? `${formattedDiffHours}:${formattedDiffMinutes}:${formattedDiffSeconds}`
                                        : item.statusProcessamento === 5
                                            ? `${formattedDiffHours}:${formattedDiffMinutes}:${formattedDiffSeconds}`
                                            : `${formattedDiffHoursBd}:${formattedDiffMinutesBd}:${formattedDiffSecondsBd}`;

                                return (
                                    <tr key={i}>
                                        <td>{item.nome_Arquivo}</td>
                                        <td>{formattedDateTime}</td>
                                        <td>{tempoProcessamento}</td>
                                        <td>{item.status === 0 ? "Em Fila" : item.status === 1 ? "Processando" : item.status === 2 ? "Importado" : item.status == 3 ? "Alerta" : "Erro"}</td>
                                        <td>{item.quantidadeLinhasArquivo === null ? "-" : item.quantidadeLinhasArquivo}</td>
                                        <td>{item.status === 0 || item.status === 1 ? "-" : item.total_Inserts}</td>
                                        <td>{item.porcentagem_Achados === null ? "-" : item.porcentagem_Achados + "%"}</td>
                                        <td>{item.tipo_Layout === null ? "-" : item.tipo_Layout}</td>
                                        <td>{item.status !== 3 && item.status !== 4 ? "-" : <button onClick={() => GetFileLog(item.id)}><FaFile size={20} /></button>}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>}
                <Pagination
                    ref={refPagination}
                    data={history}
                    countPerPage={10}
                    setRecords={setRecords}
                />
            </div>
        </section>
    );
}

export default ImportHistory;