import React, { createRef } from 'react';
import './history.css';
import { MdDownloadForOffline } from 'react-icons/md';
import { AiFillFile } from 'react-icons/ai';
import { DownloadFile } from '../../Util/FileUtil';
import { GetDateNow, CompareDate } from '../../Util/DateUtil';
import { closeAlert, openAlert } from '../../Util/AlertUtil';
import { api } from '../../components/Api/api';
import { Decrypt } from '../../Util/Crypto';
import { ScreenEnum } from '../../Util/Screen';
import Pagination from '../../components/Pagination/pagination.jsx';
import { FaSpinner } from "react-icons/fa";
import axios from 'axios';
import Table from '../../components/Form/Table.jsx';

class SearchAllCpf extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            result: [],
            phoneHistory: [],
            isColumn: false,
            isFile: false,
            searchHistory: [],
            records: [],
            typePhone: 0
        }
    }

    refPagination = createRef();

    getHistory = () => {
        let user = window.localStorage.getItem("user");
        user = JSON.parse(Decrypt(user));
        api.get(`File/GetHistoricoExcel?usuario_id=${user.usuario_id}&tela=${ScreenEnum.BUSCAR_DADOS_ALL_CPF}`, res => {
            this.setState({ searchHistory: res.data });
            this.refPagination.current.Reload(res.data);
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })
    }



    componentDidMount() {
        this.getHistory();
        this.timerID = setInterval(
            () => this.chamarGetHistory(), // Chama chamarGetHistory() a cada 40 segundos
            4000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID); // Limpa o intervalo quando o componente for desmontado
    }

    chamarGetHistory = () => {
        let user = window.localStorage.getItem("user");
        user = JSON.parse(Decrypt(user));
        axios.get(`${api.urlBase}File/GetHistoricoExcel?usuario_id=${user.usuario_id}&tela=${ScreenEnum.BUSCAR_DADOS_ALL_CPF}`).then(res => {
            this.setState({ ...this.state, searchHistory: res.data });
            this.refPagination.current.Reload(res.data);
        }).catch(erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        });
    }


    render() {
        const { phoneHistory, searchHistory, isColumn, isFile } = this.state;

        const haddleSubmit = () => {
            let user = window.localStorage.getItem("user");
            user = JSON.parse(Decrypt(user));

            var input = document.querySelector('input[type="file"]')
            let nome_Arquivo = "";
            var data = new FormData()
            data.append('User_Id', parseInt(user.usuario_id));
            data.append('TipoRetorno', document.getElementById("isRow").checked);
            data.append('ManterArquivo', document.getElementById("NaoManterArquivo").checked);
            for (const file of input.files) {
                nome_Arquivo = file.name;
                data.append('Arquivos', file, file.name)
            }
            data.append('TipoTelefone', parseInt(this.state.typePhone))

            openAlert('Arquivo Importado.\n Aguarde em tela por gentileza.', false, true);

            this.state.records.push({
                id: 0,
                nome_Arquivo,
                total_Inserts: 0,
                total_Achados: 0,
                total_Nao_Achados: 0,
                quantidade_Telefones_Achados: 0,
                porcentagem_Achados: 0,
                data_Cadastro: new Date(),
                statusProcessamento: 1
            });

            this.refPagination.current.Reload(this.state.records.reverse());

            document.querySelector('input[type="file"]').value = "";

            axios.post(`${api.urlBase}Cpf/BuscarAllCpf`, data, {
                headers: {
                    "Authorization": `Bearer ${api.access_token ? api.access_token : ""}`
                }
            }).then(res => {
            }).catch(erro => {
                if (Array.isArray(erro.response.data)) {
                    let erros = "";
                    erro.response.data.forEach(element => {
                        erros += "\n" + element.descricao + "\n";
                    });
                    openAlert('Algo deu errado\n erro:' + erro, false)
                }
                else {
                    openAlert('Algo deu errado\n erro:' + erro.response.data, false)
                }
            });
        }

        const handleFileChange = (e) => {
            const file = e.target.files[0];
            if (file) {
                const fileName = file.name;
                if (!fileName.endsWith('.csv')) {
                    openAlert('Selecione um arquivo CSV.', false, true);
                    return;
                }
                haddleSubmit(file);
                document.getElementById("filename").innerHTML = `Arquivo selecionado: ${fileName}`
            } else {
                openAlert('Necessário importar um arquivo!', false)
            }
        };

        const downloadFileHistory = (id) => {
            api.get(`File/GetDownloadExcel?arquivo_id=${id}`, res => {
                let csvContent = atob(res.data);
                var blob = new Blob([csvContent], { type: "data:application/octet-stream;base64" });
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url
                var arquivo = this.state.records.filter(x => x.id == id)[0];
                a.download = "retorno_" + arquivo.nome_Arquivo + ".csv";
                a.click();
            }, erro => {
                openAlert('Algo deu errado\n erro:' + erro, false)
            })
        }

        const formatTimeUnit = (unit) => unit.toString().padStart(2, '0');

        const setRecords = (state) => this.setState({ records: state })

        return (
            <section className='container'>
                <br />
                <div className="row" >
                    <div className="col-12" style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div class="card">
                            <h3>ENRIQUECIMENTO DE MAILING</h3>
                            <div class="drop_box">
                                <header>
                                    <h4 id="filename">Selecione um arquivo</h4>
                                </header>
                                <p>Arquivos suportados: .csv </p>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                    <span>Resultado em:</span>
                                    <label style={{ marginLeft: 10, marginRight: 5 }}>Linha</label>
                                    <input
                                        type='radio'
                                        id="isRow"
                                        name='isColumn'
                                        checked={!isColumn}
                                        onChange={() => this.setState({ isColumn: false })}
                                    />
                                    <label style={{ marginLeft: 10, marginRight: 5 }}>Coluna</label>
                                    <input
                                        type='radio'
                                        id="isColumn"
                                        name='isColumn'
                                        checked={isColumn}
                                        onChange={() => this.setState({ isColumn: true })}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                    <span>Gerar Layout Dinâmico?</span>
                                    <label style={{ marginLeft: 10, marginRight: 5 }}>Não</label>
                                    <input
                                        type='radio'
                                        id="NaoManterArquivo"
                                        name='ManterArquivo'
                                        checked={!isFile}
                                        onChange={() => this.setState({ isFile: false })}
                                    />
                                    <label style={{ marginLeft: 10, marginRight: 5 }}>Sim</label>
                                    <input
                                        type='radio'
                                        id="ManterArquivo"
                                        name='ManterArquivo'
                                        checked={isFile}
                                        onChange={() => this.setState({ isFile: true })}
                                    />
                                </div>
                                <div>
                                    <label>Tipo De Telefone</label>
                                    <select className='form-control' value={this.state.typePhone} onChange={e => this.setState({ typePhone: e.target.value })}>
                                        <option value={0}>TODOS</option>
                                        <option value={1}>CELULAR</option>
                                        <option value={2}>FIXO</option>
                                    </select>
                                </div>
                                <p>Obs; Layout Dinâmico é o arquivo que a consulta é feita com informações além do CPF e assim o processo pode demorar um pouco mais. </p>
                                <input onChange={handleFileChange} type="file" hidden accept=".csv" id="fileID" style={{ display: 'none' }} />
                                <button onClick={() => document.getElementById("fileID").click()} style={{ opacity: '0.7' }} class="btn btn-primary">Escolher arquivo</button>
                            </div>
                            <button onClick={() => DownloadFile('/Templates/template_buscar_lote.csv')} className="btn btn-success" type="button">Baixar Template</button>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '1px', background: 'rgba(0,0,0,0.2)', marginBottom: '10px' }} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    {searchHistory.length === 0 && <span> Não há historico de importação</span>}
                    <Table
                        colunas={["Nome do arquivo", "Data Consulta", "Tempo Processamento",
                            "Status", "Total", "Total Cpfs Achados", "Total Cpfs Não Achados",
                            "Total Telefones Achados", "Porcentagem Cpf's achados", "Resultado",
                            "Dinâmico", "Downloads"]}
                        data={this.state.records.map((item, i) => {
                            const date = new Date(item.data_Cadastro);
                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            const hours = String(date.getHours()).padStart(2, '0');
                            const minutes = String(date.getMinutes()).padStart(2, '0');
                            const seconds = String(date.getSeconds()).padStart(2, '0');

                            const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

                            const now = new Date(item.horaAtual);
                            const inicioProcessamento = item.data_Cadastro ? new Date(item.data_Cadastro) : null;
                            const fimProcessamento = item.fim_Processamento ? new Date(item.fim_Processamento) : null;
                            let difBd = (inicioProcessamento && fimProcessamento) ? inicioProcessamento - fimProcessamento : 0;
                            if (difBd < 0) {
                                difBd = (inicioProcessamento && fimProcessamento) ? fimProcessamento - inicioProcessamento : 0;
                            }
                            const diffMs = inicioProcessamento ? now - inicioProcessamento : 0;

                            const diffHoursBd = Math.floor(difBd / 3600000);
                            const diffMinutesBd = Math.floor((difBd % 3600000) / 60000);
                            const diffSecondsBd = Math.floor((difBd % 60000) / 1000);
                            const diffHours = Math.floor(diffMs / 3600000);
                            const diffMinutes = Math.floor((diffMs % 3600000) / 60000);
                            const diffSeconds = Math.floor((diffMs % 60000) / 1000);

                            const formattedDiffHours = formatTimeUnit(diffHours);
                            const formattedDiffMinutes = formatTimeUnit(diffMinutes);
                            const formattedDiffSeconds = formatTimeUnit(diffSeconds);
                            const formattedDiffHoursBd = formatTimeUnit(diffHoursBd);
                            const formattedDiffMinutesBd = formatTimeUnit(diffMinutesBd);
                            const formattedDiffSecondsBd = formatTimeUnit(diffSecondsBd);

                            const tempoProcessamento =
                                item.statusProcessamento === 0 || item.statusProcessamento === 1
                                    ? `${formattedDiffHours}:${formattedDiffMinutes}:${formattedDiffSeconds}`
                                    : item.statusProcessamento === 5
                                        ? `${formattedDiffHours}:${formattedDiffMinutes}:${formattedDiffSeconds}`
                                        : `${formattedDiffHoursBd}:${formattedDiffMinutesBd}:${formattedDiffSecondsBd}`;
                            return {
                                "Nome do arquivo": item.nome_Arquivo,
                                "Data Consulta": formattedDateTime,
                                "Tempo Processamento": item.statusProcessamento === 4 ? "00:00:00" : tempoProcessamento,
                                "Status": item.statusProcessamento === 0 ? "Pendente"
                                    : item.statusProcessamento === 1 ? "Processando"
                                        : item.statusProcessamento === 2 ? "Importado"
                                            : item.statusProcessamento === 3 ? "Alerta"
                                                : item.statusProcessamento === 4 ? "Erro"
                                                    : item.statusProcessamento === 4 ? "Lendo Arquivo" : "Concluído",
                                "Total": item.total_Inserts,
                                "Total Cpfs Achados": item.total_Achados,
                                "Total Cpfs Não Achados": item.total_Nao_Achados,
                                "Total Telefones Achados": item.quantidade_Telefones_Achados,
                                "Porcentagem Cpf's achados": item.porcentagem_Achados + '%',
                                "Resultado": item.enriquecimento_Por_Linha === null ? "-" : item.enriquecimento_Por_Linha === true ? "Linha" : "Coluna",
                                "Dinâmico": item.manter_Arquivo_Enriquecimento == null ? "-" : item.manter_Arquivo_Enriquecimento === true ? "Manter Arquivo" : "Dinâmico",
                                "Downloads":
                                    <>
                                        {item.statusProcessamento && item.statusProcessamento !== 2 && item.statusProcessamento !== 4 && (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <FaSpinner className='spinner' color='#1b6ec2' size={20} />
                                            </div>
                                        )}
                                        {item.statusProcessamento === 2 && (
                                            <button
                                                type='button'
                                                hidden={item.nome_Arquivo.indexOf("(Em processamento)") !== -1}
                                                onClick={() => downloadFileHistory(item.id)}
                                                style={{ width: '100%', padding: 10 }}
                                                className='btn-download'
                                            >
                                                Download <AiFillFile />
                                            </button>
                                        )}
                                        {item.statusProcessamento !== 2 && !item.statusProcessamento && (
                                            <button
                                                type='button'
                                                hidden={item.nome_Arquivo.indexOf("(Em processamento)") !== -1}
                                                onClick={() => downloadFileHistory(item.id)}
                                                style={{ width: '100%', padding: 10 }}
                                                className='btn-download'
                                            >
                                                Download <AiFillFile />
                                            </button>
                                        )}
                                        {item.statusProcessamento === 4 && 'Erro'}
                                    </>

                            }
                        })}
                    />
                    <Pagination
                        ref={this.refPagination}
                        data={searchHistory}
                        countPerPage={10}
                        setRecords={setRecords}
                    />
                </div>
            </section>
        );
    }
}

export default SearchAllCpf;