import React, { useEffect, useState, useRef } from 'react';
import './ImportBase.css';
import { openAlert } from '../../Util/AlertUtil';
import { api } from '../../components/Api/api.js';
import { Decrypt } from "../../Util/Crypto";
import { ScreenEnum } from '../../Util/Screen';
import Pagination from '../../components/Pagination/pagination.jsx';
import SelectLayout from '../../components/Modals/LayoutBase/SelectLayout.jsx';
import { FaFile } from "react-icons/fa";
import axios from "axios";
import { FaSpinner } from 'react-icons/fa6';
import { FaGear } from "react-icons/fa6";
import Table from '../../components/Form/Table.jsx';

const ImportBase = (props) => {
    const [history, setHistory] = useState([]);
    const [records, setRecords] = useState([]);
    const [layoutSelectedText, setLayoutSelectedText] = useState('');
    const [layoutSelectedId, setLayoutSelectedId] = useState(0);
    const [selectedLayout, setSelectedLayout] = useState(null);
    const refPagination = useRef();
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvFirstLine, setFirstLine] = useState([]);
    const [selectedLayouts, setSelectedLayouts] = useState(Array(csvHeaders.length).fill(''));
    const [fileSelect, setFileSelect] = useState(null);
    const [visualizacaoDados, setVisualizacaoDados] = useState([]);
    const [loaderVisualizacaoDados, setloaderVisualizacaoDados] = useState(false);
    const [map, setMap] = useState({
        OPERADORA: -1,
        CPF: -1,
        DATAATUALIZACAO: -1,
        SCORE: -1,
        TELEFONE: -1,
        DDD: -1,
        FACEBOOK: -1,
        WHATSAPP: -1,
        QUANTIDADELINHASARQUIVO: -1,
        QUANTIDADETELEFONESARQUIVO: -1,
        EXISTENTESTELEFONIA: -1
    });

    const getHistoryExcel = () => {
        let user = window.localStorage.getItem("user");
        user = JSON.parse(Decrypt(user));
        api.get(`File/GetHistoricoExcel?usuario_id=${user.usuario_id}&tela=${ScreenEnum.IMOPORTAR_MAILING}`, res => {
            setHistory(res.data);
            refPagination.current.Reload(res.data);
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })
    }

    const refrashPage = async _ => {
        try {
            let user = window.localStorage.getItem("user");
            user = JSON.parse(Decrypt(user));

            const config = {
                headers: {
                    Authorization: 'Bearer ' + api.access_token
                }
            };
            const uri = `${api.urlBase}File/GetHistoricoExcel?usuario_id=${user.usuario_id}&tela=${ScreenEnum.IMOPORTAR_MAILING}`;
            const response = await axios.get(uri, config);
            const jsonData = response.data;

            setHistory(jsonData);
            refPagination.current.Reload(jsonData);
        } catch (error) {
            console.error('Ocorreu um erro ao buscar os dados:', error.message);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            refrashPage();
        }, 5000)
    }, [history])

    useEffect(() => {
        getHistoryExcel();
    }, [])

    const ReloadPage = () => {
        let user = window.localStorage.getItem("user");
        user = JSON.parse(Decrypt(user));
        api.get(`File/GetHistoricoExcel?usuario_id=${user.usuario_id}&tela=${ScreenEnum.IMOPORTAR_MAILING}`, res => {
            setHistory(res.data);
            refPagination.current.Reload(res.data);
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })
    }

    const haddleSubmit = () => {


        const file = fileSelect;
        const hasRedCell = visualizacaoDados.some((item, index) => item.HeaderCSV !== item.ItemLayout);
        if (hasRedCell) {
            openAlert('Há uma ou mais colunas com erros. Por favor, corrija antes de enviar o arquivo.', false, true);
            setLayoutSelectedText('');
            setFileSelect('');
            clearFileInput();
            setCsvHeaders('');
            setFirstLine();
            var input = document.querySelector('input[type="file"]')
            input.value = '';
            refrashPage();
            return;
        }
        else if (file != null) {
            let user = window.localStorage.getItem("user");
            user = JSON.parse(Decrypt(user));

            const data = new FormData();
            // var input = document.querySelector('input[type="file"]');
            // for (const file of input.files) {
            data.append('file', file, file.name)
            // }
            console.log("map0");
            console.log(map);
            const mapeamento = {
                Operadora: map.OPERADORA,
                CPF: map.CPF,
                Data_Atualizacao: map.DATAATUALIZACAO,
                Score: map.SCORE,
                Tel: map.TELEFONE,
                DDD: map.DDD === 0 ? -1 : map.DDD,
                Origem: map.ORIGEM,
                Facebook: map.FACEBOOK,
                Whatsapp: map.WHATSAPP,
            };

            data.append('mapeamentoTxt', JSON.stringify(mapeamento));
            data.append('nomeLayoutOrigem', layoutSelectedText);
            data.append('numeroLayoutOritem', layoutSelectedId);

            api.post("File/UploadTelefonia", data, res => {
                openAlert(res.data.mensagem);
                if (res.data.arquivoB64) {
                    DownloadFile(res.data.arquivoB64);
                }
                window.location.href = '/importbase';
            }, erro => {
                input.value = '';
                if (Array.isArray(erro.response.data)) {
                    let erros = "";
                    erro.response.data.forEach(element => {
                        erros += "\n" + element.descricao + "\n";
                    });
                    openAlert('Algo deu errado\n erro:' + erros, false)
                    ReloadPage()
                    setLayoutSelectedText('');
                    setLayoutSelectedId(0);
                }
                else {
                    ReloadPage()
                    openAlert('Algo deu errado\n erro:' + erro.response.data, false)
                    setLayoutSelectedText('');
                    setLayoutSelectedId(0);
                }
            });
        } else {
            openAlert('Selecione um arquivo', false, true)
        }

    }
    const clearFileInput = () => {
        document.getElementById("filename").innerHTML = `Selecione um arquivo.`;
    }

    function removerAcentos(texto) {
        return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    const handleFileChange = (e) => {
        setloaderVisualizacaoDados(true);
        const file = e.target.files[0];
        const fileName = file.name;
        var input = document.querySelector('input[type="file"]')
        if (!fileName.endsWith('.csv')) {
            input.value = '';
            openAlert('Selecione um arquivo CSV.', false, true);
            return;
        }

        var data = new FormData();
        data.append('file', file, fileName)
        setFileSelect(file);
        axios.post(api.urlBase + 'File/CarregarPreVisualizar/' + layoutSelectedId, data, {
            headers: {
                "Authorization": `Bearer ${api.access_token}`
            }
        }).then(response => {
            setVisualizacaoDados(response.data);
            setloaderVisualizacaoDados(false);
        }).catch(err => {
            console.clear();
            console.log(err);
            setloaderVisualizacaoDados(false);
            openAlert("Houve um erro ao pré visualizar o arquivo", false);
            input.value = '';
        })

        // document.getElementById("filename").innerHTML = `Arquivo selecionado: ${fileName}`

        // reader.readAsText(file, 'ISO-8859-1');
        // setloaderVisualizacaoDados(false);
        //haddleSubmit(file);
    };

    const validaOperadora = (index, header, value) => {
        if (header === "CPF") {
            const cpfFormatado = value.replace(/^0*(\d{1,11})$/, '$1').padStart(11, "0");
            return cpfFormatado;
        } else if (header == 'OPERADORA' && value == '') {
            return "Diversos";
        } else { return value; }
    }

    const renderCsvHeaderWithLayoutSelect = () => {
        return (
            <table className='table table-striped' style={{ marginTop: '20px' }}>
                <thead>
                    <tr>
                        {visualizacaoDados.map(({ headerCSV }) => (
                            <th key={headerCSV}><strong>{headerCSV}</strong></th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {visualizacaoDados.map(({ itemLayout },) => (
                            <td key={itemLayout}>
                                {itemLayout}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        );
    };


    const DownloadFile = (file) => {
        let csvContent = atob(file);
        var blob = new Blob([csvContent], { type: "data:application/octet-stream;base64" });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url
        a.download = "log.csv";
        a.click();
    }

    const GetFileLog = (id) => {
        api.get(`File/GetDownloadExcel?arquivo_id=${id}&ehLog=true`, res => {
            DownloadFile(res.data);
        }, erro => {
            openAlert('Algo deu errado\n erro:' + erro, false)
        })
    }

    const GetLayout = async () => {
        try {
            const response = await api.getAsync(`File/BuscarLayout?layoutId=${layoutSelectedId}`);
            console.log("response", response.data);
            return response.data; // Retorna os dados assim que a chamada API é concluída
        } catch (erro) {
            openAlert('Algo deu errado\n erro:' + erro, false);
            console.error('Erro ao buscar os detalhes do layout:', erro);
            return null; // Retorna null em caso de erro
        }
    };

    const formatTimeUnit = (unit) => unit.toString().padStart(2, '0');


    // Função para criar o objeto de visualização
    function criarObjetoVisualizacao(layout, headers, firstLine) {
        console.log(layout);
        console.log(headers);
        console.log(firstLine);
        const visualizacaoObj = layout
            //.sort((a, b) => a.posicao - b.posicao) // Ordena os itens do layout pela posição
            .map(itemLayout => {
                const posicao = itemLayout.posicao; // Ajuste da posição para índice do array
                const header = headers[posicao];
                const valor = firstLine[posicao];

                return {
                    ItemLayout: itemLayout.descricao,
                    HeaderCSV: header,
                    ValorPrimeiraLinha: valor
                };
            });
        console.log(visualizacaoObj);
        console.log("visualizacaoObj");
        setVisualizacaoDados(visualizacaoObj);
    }

    const haddleVoltar = () => {
        setVisualizacaoDados([])
        setCsvHeaders([]);
        setFileSelect(null);
        setFirstLine([]);
        setCsvHeaders([]);
        setMap({
            OPERADORA: -1,
            CPF: -1,
            DATAATUALIZACAO: -1,
            SCORE: -1,
            TELEFONE: -1,
            DDD: -1,
            FACEBOOK: -1,
            WHATSAPP: -1,
            QUANTIDADELINHASARQUIVO: -1,
            QUANTIDADETELEFONESARQUIVO: -1,
            EXISTENTESTELEFONIA: -1
        });
    }

    return (
        <section className='container'>
            <br />
            {!loaderVisualizacaoDados && visualizacaoDados.length === 0 && <div className="row" >
                <div className="col-12" style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div class="card">
                        <h3>Importação de Base Telefônica</h3>
                        <div class="drop_box">
                            <header>
                                <h4 id="layoutSelected">{layoutSelectedText}</h4>
                                <h4 id="filename">Selecione um arquivo</h4>
                            </header>
                            <p>Arquivos suportados: .csv </p>
                            {console.log(handleFileChange)}
                            <input onChange={handleFileChange} type="file" hidden accept=".csv" id="fileID" style={{ display: 'none' }} />
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', width: '100%' }}>
                                <SelectLayout
                                    setSelectedLayout={setSelectedLayout}
                                    setLayoutSelectedText={setLayoutSelectedText}
                                    setLayoutSelectedId={setLayoutSelectedId}
                                />
                                {
                                    layoutSelectedText != '' && layoutSelectedId !== 0 &&
                                    <button onClick={() => document.getElementById("fileID").click()} style={{ opacity: '0.7' }} class="btn btn-primary">Escolher arquivo</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {loaderVisualizacaoDados && <div style={{ display: 'flex', justifyContent: 'center' }}><FaGear className='spinner' color='#1b6ec2' size={50} /><br /></div>}
            <div>
                {!loaderVisualizacaoDados && visualizacaoDados.length > 0 && (
                    <div class="drop_box" style={{ overflowX: 'auto', width: '100%', display: 'block' }}>
                        <h2>Pré-visualização</h2>
                        <right><h6>1 - Primeira linha é do Layuout cadastrado.</h6>
                            <h6>2 - Segunda linha é o cabeçalho do CSV.</h6>
                            <h6>3 - Terceira linha é uma amostra do valor encontrado no CSV para validação da estrutura do layout.</h6>
                            <h6>4 - Coluna em vermelho possui divergência com o layout cadastrado.</h6>
                            <h9>Obs: Caso os valores não correspondam com o cabeçalho, favor validar o Layout cadastrado. O mesmo deve está de acordo com o Excel levando em consideção a posição das colunas.</h9>
                        </right>
                        {renderCsvHeaderWithLayoutSelect()}
                        {visualizacaoDados.length == 0 && <div style={{ display: 'flex', justifyContent: 'center', padding: 10,marginBottom:'10px' }}><FaGear className='spinner' color='#1b6ec2' size={50} /><br /></div>}
                        <div style={{ width: '100%', height: '1px', background: 'rgba(0,0,0,0.2)', marginBottom: '10px' }} />
                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                            <button onClick={haddleVoltar} style={{ opacity: '0.7', textAlign: 'center' }} class="btn btn-primary">Voltar</button>
                            <button onClick={haddleSubmit} style={{ opacity: '0.7', textAlign: 'center' }} class="btn btn-primary">Enviar Arquivo</button>
                        </div>
                    </div>
                )}
            </div>

            {/* <div style={{ width: '100%', height: '1px', background: 'rgba(0,0,0,0.2)', marginBottom: '10px' }} /> */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {history.length === 0 && <span> Não há historico de importação</span>}
                {history.length > 0 && <div style={{ width: '85vw', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Table
                        colunas={[
                            "Nome do arquivo", "Data de envio", "Tempo Processamento",
                            "Status", "Total linhas", "Total Telefones", "Total Telefones importados",
                            "Existentes Telefonia", "Registros Com Erro", "Percentual", "Log"
                        ]}
                        data={records.map((item) => {
                            const date = new Date(item.data_Cadastro);
                            const formattedDateTime = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;

                            const now = new Date(item.horaAtual);
                            const inicioProcessamento = item.data_Cadastro ? new Date(item.data_Cadastro) : null;
                            const fimProcessamento = item.fim_Processamento ? new Date(item.fim_Processamento) : null;
                            const difBd = (inicioProcessamento && fimProcessamento) ? fimProcessamento.getTime() - inicioProcessamento.getTime() : 0;
                            const diffMs = inicioProcessamento ? now.getTime() - inicioProcessamento.getTime() : 0;
                            let tempoProcessamento = '';

                            if (difBd > 0) {
                                const formattedDiffHours = formatTimeUnit(Math.floor(diffMs / 3600000));
                                const formattedDiffMinutes = formatTimeUnit(Math.floor((diffMs % 3600000) / 60000));
                                const formattedDiffSeconds = formatTimeUnit(Math.floor((diffMs % 60000) / 1000));
                                const formattedDiffHoursBd = formatTimeUnit(Math.floor(difBd / 3600000));
                                const formattedDiffMinutesBd = formatTimeUnit(Math.floor((difBd % 3600000) / 60000));
                                const formattedDiffSecondsBd = formatTimeUnit(Math.floor((difBd % 60000) / 1000));

                                tempoProcessamento =
                                    item.statusProcessamento === 0 || item.statusProcessamento === 1
                                        ? `${formattedDiffHours}:${formattedDiffMinutes}:${formattedDiffSeconds}`
                                        : (item.statusProcessamento === 5 && inicioProcessamento !== null)
                                            ? `${formattedDiffHours}:${formattedDiffMinutes}:${formattedDiffSeconds}`
                                            : `${formattedDiffHoursBd}:${formattedDiffMinutesBd}:${formattedDiffSecondsBd}`;
                            } else {
                                tempoProcessamento = "-"
                            }

                            return {
                                "Nome do arquivo": item.nome_Arquivo,
                                "Data de envio": formattedDateTime,
                                "Tempo Processamento": tempoProcessamento,
                                "Status": item.statusProcessamento === 5 ? "Lendo Arquivo" : item.statusProcessamento === 0 ? "Aguardando Processamento" : item.statusProcessamento === 1 ? "Em Processamento" : item.statusProcessamento === 2 ? "Concluído" : "Erro",
                                "Total linhas": item.quantidadeLinhasArquivo === null ? "-" : item.quantidadeLinhasArquivo,
                                "Total Telefones": item.quantidadeTelefonesArquivo == null ? "-" : item.quantidadeTelefonesArquivo,
                                "Total Telefones importados": item.statusProcessamento === 0 || item.statusProcessamento === 1 ? "-" : item.total_Inserts,
                                "Existentes Telefonia": item.quantidadeTelefonesArquivo - item.total_Inserts,
                                "Registros Com Erro": item.quantidade_Registros_Erro,
                                "Percentual": item.porcentagem_Achados === null ? "-" : item.porcentagem_Achados === 0 ? Math.floor((item.total_Inserts / item.quantidadeTelefonesArquivo) * 100) + "%" : item.porcentagem_Achados + "%",
                                "Log": item.quantidade_Registros_Erro === 0 ? "-" : <button onClick={() => GetFileLog(item.id)}><FaFile size={20} /></button>
                            };
                        })}
                    />
                </div>}
                <Pagination
                    ref={refPagination}
                    data={history}
                    countPerPage={10}
                    setRecords={setRecords}
                />
            </div>
        </section>
    );
}

export default ImportBase;