import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default ({ colunas, data }) => (
    <div style={{ width: '85vw', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <table className='table table-striped'>
            <thead>
                <tr>
                    {colunas.map((coluna, index) => (
                        <th key={index}>{coluna}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {colunas.map((coluna, colIndex) => (
                            <td key={colIndex}>
                                {coluna === 'Nome do arquivo' ? (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-${rowIndex}-${colIndex}`}>{row[coluna]}</Tooltip>}
                                    >
                                        <span>
                                            {row[coluna].length > 20 ? `${row[coluna].substring(0, 20)}...` : row[coluna]}
                                        </span>
                                    </OverlayTrigger>
                                ) : (
                                    row[coluna]
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);
